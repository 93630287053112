/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable wrap-iife */
import { Base64 } from 'js-base64';
import { AVATAR_URL, SITE_URL } from 'constants/api';
import SUCCESS_MESSAGE from 'constants/successMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import color from '@deprecated-ui/_style/color';

/* eslint-disable import/prefer-default-export */
const copyTextToClipboard = (text, customAlert = null) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  const body = document.querySelector('body');
  input.value = text;
  body.appendChild(input);
  input.select();
  document.execCommand('copy');
  body.removeChild(input);

  if (customAlert) {
    customAlert();
  } else {
    handleErrorMessageAPI('Link copied', SUCCESS_MESSAGE.ALERT_GREEN);
  }
};

function isMobileVersion() {
  let check = false;
  // eslint-disable-next-line func-names
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

function popupWindowToCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`,
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

function getLastSlashURL(url) {
  return url.substr(url.lastIndexOf('/') + 1);
}

function handleErrorAvatar(e, paramUsername) {
  const fallbackUrl = `${SITE_URL}/images/avatar.png`;
  e.target.onerror = null;
  e.target.src = fallbackUrl;
}

function parsePureValueSep(pv) {
  if (typeof pv !== 'number') pv = parseFloat(pv);
  return parseFloat(pv.toFixed(2)).toLocaleString('en-US');
}

const parseCountAbbrv2 = (number, decPlaces, thoSep = ',') => {
  let result = 0;

  if (typeof decPlaces !== 'number') decPlaces = 3;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = 10 ** decPlaces;

  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B'];
  const oldNumber = number;

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }

      // Add the letter for the abbreviation
      number += ` ${abbrev[i]}`;

      // We are done... stop
      break;
    }
  }

  let raw = [];

  if (number) {
    number = number.toString();
    raw = number.split(' ');
    if (raw[0] && raw[1]) {
      result = parsePureValueSep(raw[0]);
      result = raw[1] && raw[1] !== 'K' ? `${result} ${raw[1]}` : result;
      if (raw[1] === 'K') {
        result = parseFloat(oldNumber);
        result = parsePureValueSep(result);
      }
    } else {
      result = number;
    }
  }

  return result;
};

function sortAlpha(a, b) {
  const ax = a.toLowerCase();
  const bx = b.toLowerCase();
  return ax < bx ? -1 : ax > bx ? 1 : 0;
}
/**
 * Convert data to event change data to be used in `useForm` hooks
 * @param {*} val
 * @returns {Object}
 */
const convertToEventValue = (val) => {
  const data = {
    target: {
      value: val,
    },
  };

  return data;
};

/**
 * Decode string to html text area
 * @param {string} html
 * @return {string}
 */
function decodeHtml(html) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = html;
  return textarea.value;
}

/**
 * Convert text to slug
 * @param {string} text
 * @return {string}
 */
function convertToSlug(text) {
  if (!text) return text;
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-$/, ''); // Trim - from end of text
}

/**
 * Capitalize
 * @param {string} string
 * @return {string}
 */
function capitalizeFirstLetter(string) {
  if (!string) return string;
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
}

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

/**
 * Capitalizer
 * @param {string} string
 * @param {number} at
 * @return {string}
 */
function capitalizer(string, at = 0) {
  if (!string) return string;
  const upperCasedLetter = string.charAt(at).toUpperCase();
  return setCharAt(string, at, upperCasedLetter);
}

// helpers
const encodeBase64FromJS = (object) => {
  let result = object;
  if (typeof object === 'object') {
    result = JSON.stringify(object);
  }

  return Base64.encode(result);
};

const decodeBase64ToJS = (base64) => {
  const decoded = Base64.decode(base64);
  let result = decoded;
  try {
    result = JSON.parse(decoded);
  } catch (e) {
    result = decoded;
  }
  return result;
};

// local storage utils
const saveObject = (key, object) => {
  window.localStorage.setItem('location', window.location.origin);
  window.localStorage.setItem(key, encodeBase64FromJS(object));
};

/**
 * Turn newline into <br/>
 * @param {string} str
 * @param {string=} isXhtml
 * @returns {string}
 */
function newlineToBr(str, isXhtml) {
  const breakTag =
    isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

function emptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, match, replacement) {
  return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
}

const getColorBasedOnNumber = (number) => {
  if (Number(number) > 0) return color.primary;
  if (Number(number) < 0) return color.sunsetOrange;
  return color.highempahis;
};

function getPathFromUrl(url) {
  return url.split('?')[0];
}

function trimText(description, maxLength) {
  let trimmedString = description.substr(0, maxLength);

  const lastStringIndex = Math.min(
    trimmedString.length,
    trimmedString.lastIndexOf(' '),
  );

  trimmedString = trimmedString.substr(0, lastStringIndex);

  if (description.length > maxLength) {
    trimmedString += '...';
  }

  return trimmedString;
}

const transformAvatarUrl = (avatarUrl = '') => {
  if (avatarUrl.startsWith('http')) {
    return avatarUrl;
  }
  return `${AVATAR_URL}/${avatarUrl}`;
};

/**
 * sanitizeHTMLTags
 * Remove Curve Brackets from string
 * to prevent xss, security issues
 *
 * This function is created because regex "/(<([^>]+)>)/gi" that already
 * used in v2 is not accepted in sonar-scan v3.
 * This function already have unit test
 * to makesure the result same as the previous regex implementation.
 * @param {String} originalString
 * @returns
 */
const sanitizeHTMLTags = (originalString = '') => {
  let result = '';
  let start = 0;
  while (true) {
    const startBracket = originalString.indexOf('<', start);
    if (startBracket === -1) break;
    const endBracket = originalString.indexOf('>', startBracket);
    if (endBracket === -1) break;
    if (endBracket - startBracket === 1) {
      result += originalString.substring(
        startBracket - (startBracket - start),
        endBracket + 1,
      );
    } else {
      result += originalString.substring(start, startBracket);
    }
    start = endBracket + 1;
  }
  result += originalString.substring(start);
  return result;
};

function sanitizeURL(urlToCheck = '/') {
  if (urlToCheck[0] === '/') {
    return urlToCheck;
  }
  try {
    const safeProtocol = ['https', 'http'];
    const safeHost = [
      'stockbit.com',
      'ui.lab.stockbit.com',
      'itunes.apple.com',
      'play.google.com',
    ];
    const url = new URL(urlToCheck);
    if (safeProtocol.indexOf(url?.protocol) < 0) {
      throw Error(`Not safe protocol: ${url?.protocol}`);
    }
    if (safeHost.indexOf(url?.host) < 0) {
      throw Error(`Not safe host: ${url?.host}`);
    }
    return urlToCheck;
  } catch (err) {
    console.error(err);
    return SITE_URL;
  }
}

export {
  copyTextToClipboard,
  isMobileVersion,
  popupWindowToCenter,
  getLastSlashURL,
  handleErrorAvatar,
  parseCountAbbrv2,
  sortAlpha,
  convertToEventValue,
  decodeHtml,
  convertToSlug,
  capitalizeFirstLetter,
  setCharAt,
  capitalizer,
  sanitizeURL,
  // localStorage utils
  encodeBase64FromJS,
  decodeBase64ToJS,
  saveObject,
  newlineToBr,
  emptyObject,
  escapeRegExp,
  replaceAll,
  getColorBasedOnNumber,
  getPathFromUrl,
  trimText,
  transformAvatarUrl,
  sanitizeHTMLTags,
};
